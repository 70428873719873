<template>
  <div :class="`hero-slider__slide__content ${sanePosition}`">
    <div :class="`background-text-container ${textColor}`">
      <span class="background-text-container__text">{{ backgroundText }}</span>
    </div>
    <HeroSliderText />
  </div>
</template>

<script>
import { defineComponent, inject } from '@nuxtjs/composition-api';
import HeroSliderText from '~/components/Homepage/HeroSlider/HeroSliderText.vue';

export default defineComponent({
  name: 'HeroSliderContent',
  components: {
    HeroSliderText,
  },
  props: {
    backgroundText: {
      type: String,
      default: '',
    },
  },
  setup() {
    const position = inject('position');
    const textColor = inject('textColor');

    const sanePosition =
      position &&
      [
        'center',
        'bottom-left',
        'bottom-right',
        'top-right',
        'top-left',
      ].includes(position)
        ? position
        : 'center';

    return {
      sanePosition,
      textColor,
    };
  },
});
</script>

<style lang="scss">
.hero-slider__slide__content {
  position: absolute;
  z-index: 2;
  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 768px) {
      width: 100%;
      max-width: 80%;
    }
  }
  &.bottom-left {
    top: 85%;
  }
  &.bottom-right {
    top: 85%;
  }
  &.top-right {
    top: 25%;
  }
  &.top-left {
    top: 25%;
  }
  .background-text-container {
    position: relative;
    z-index: 1;
    width: 100%;
    @include hero-number;
    &.black {
      -webkit-text-stroke-color: var(--c-black);
    }
    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -25%);
      @include for-desktop {
        transform: translate(-50%, -40%);
      }
    }
    @media (max-width: 768px) {
      font-size: 184px;
    }
  }
}

@include from-desktop-min {
  .hero-slider__slide__content {
    &.bottom-left {
      top: 85%;
      right: 50%;
      transform: translate(-10%, -35%);
    }
    &.bottom-right {
      top: 85%;
      left: 50%;
      transform: translate(10%, -35%);
    }
    &.top-right {
      top: 25%;
      left: 50%;
      transform: translate(10%, -35%);
    }
    &.top-left {
      top: 25%;
      right: 50%;
      transform: translate(-10%, -35%);
    }
  }
}
</style>
