<template>
  <div class="hero-slider__slider__content__box">
    <div :class="`hero-slider__slider__content__box__text ${textColor}`">
      {{ overlayText }}
    </div>
    <HeroSliderCtas class="hero-slider__slider__content__box__ctas" />
  </div>
</template>

<script>
import { defineComponent, inject } from '@nuxtjs/composition-api';
import HeroSliderCtas from './HeroSliderCtas.vue';

export default defineComponent({
  name: 'HeroSliderText',
  components: { HeroSliderCtas },
  props: {
    title: {
      type: String,
      required: '',
    },
  },
  setup() {
    const textColor = inject('textColor');
    const overlayText = inject('overlayText');
    return {
      overlayText,
      textColor,
    };
  },
});
</script>

<style lang="scss">
.hero-slider__slider__content__box {
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 58.5625rem;
  &__text {
    @include mobile-h2;
    color: var(--c-white);
    text-transform: uppercase;
    @include for-mobile {
      position: relative;
      top: 3rem;
    }
    @include for-desktop {
      @include hero-h1;
      max-height: 5.25rem;
    }
    &.black {
      color: var(--c-black);
    }
    @include for-mobile {
      line-height: 44px;
    }
  }
  &__ctas {
    padding-top: 1.25rem;
    display: flex;
    justify-content: center;
    a {
      margin-left: -1px;
      @include for-mobile {
        padding: 10px 15px;
        height: auto;
        width: auto;
      }
    }
    .custom-button:hover,
    .custom-button__disabled {
      background: #000 !important;
    }
    @include for-mobile {
      position: absolute;
      bottom: -8rem;
      gap: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
    @include for-desktop {
      gap: 1.25rem;
      justify-content: center;
    }
  }
}
</style>
