<template>
  <section
    class="hero-slider"
    :class="{ 'loading-hero-content': loadingHeroContent }"
    v-if="hasContentToShow"
  >
    <HeroSliderContent
      :background-text="
        sliderData && sliderData.background_text
          ? sliderData.background_text
          : ''
      "
    />
    <div class="swiper-slider-wrap">
      <div v-swiper="swiperOption" :instance-name="sliderId">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(slide, index) in slides"
            :key="`hero-slide-container-${index}`"
          >
            <HeroSlide
              :slider-length="slides.length"
              :slide-data="slide"
              :slide-index="index"
              @slideLoaded="loadingHeroContent = false"
            />
          </div>
        </div>
      </div>
      <div class="s-arrow" v-if="slides.length > 1">
        <div
          :id="sliderId + '-button-prev'"
          class="swiper-button-prev button-prev"
          slot="button-prev"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32.121"
            height="61.414"
            viewBox="0 0 32.121 61.414"
          >
            <path
              id="Tracciato_2273"
              data-name="Tracciato 2273"
              d="M322.735,2010.93l30,30-30,30"
              transform="translate(354.149 2071.637) rotate(180)"
              fill="none"
              stroke="#DEDEDE"
              stroke-width="2"
            />
          </svg>
        </div>
        <div
          :id="sliderId + '-button-next'"
          class="swiper-button-next button-next"
          slot="button-next"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32.121"
            height="61.414"
            viewBox="0 0 32.121 61.414"
          >
            <path
              id="Tracciato_2274"
              data-name="Tracciato 2274"
              d="M322.735,2010.93l30,30-30,30"
              transform="translate(-322.028 -2010.223)"
              fill="none"
              stroke="#DEDEDE"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import SwiperCore, { Navigation } from 'swiper';
import { directive } from 'vue-awesome-swiper';
import 'swiper/swiper.scss';
SwiperCore.use([Navigation]);
import {
  defineComponent,
  ref,
  computed,
  provide,
} from '@nuxtjs/composition-api';
import HeroSlide from '~/components/Homepage/HeroSlider/HeroSlide.vue';
import HeroSliderContent from './HeroSliderContent.vue';
export default defineComponent({
  name: 'HeroSlider',
  components: {
    HeroSlide,
    HeroSliderContent,
  },
  props: {
    sliderData: {
      type: [Array, Object],
    },
    sliderId: {
      type: String,
      default: 'home-hero-slider',
    },
  },
  setup(props) {
    provide('ctasData', props.sliderData.ctas);
    provide('overlayText', props.sliderData.text);
    provide('textColor', props.sliderData.text_color);
    provide('position', props.sliderData.text_position);
    const loadingHeroContent = ref(true);
    const slides = computed(() =>
      props.sliderData?.slides?.filter(
        (slide) =>
          slide.active &&
          !!(slide.isVideo
            ? slide.video_url_desktop && slide.video_url_mobile
            : slide.image.desktop && slide.image.mobile)
      )
    );
    const hasContentToShow = computed(
      () =>
        props.sliderData.slides &&
        Array.isArray(props.sliderData.slides) &&
        props.sliderData.slides.length > 0
    );
    return {
      hasContentToShow,
      slides,
      loadingHeroContent,
    };
  },
  data() {
    return {
      swiperOption: {
        loop: false,
        observer: true,
        observeParents: true,
        resizeObserver: true,
        navigation: {
          nextEl: `#${this.sliderId}-button-next`,
          prevEl: `#${this.sliderId}-button-prev`,
        },
        breakpoints: {
          0: {
            slidesPerView: '1',
            spaceBetween: 0,
            allowTouchMove: true,
          },
          767: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          1200: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        },
      },
    };
  },
  directives: {
    swiper: directive,
  },
});
</script>

<style lang="scss">
.hero-slider {
  width: 100%;
  position: relative;
  z-index: 1;
  &__glide {
    height: 100%;
    &__track {
      height: 100%;
      &__slides {
        height: 100%;
        &__slide {
          height: auto;
        }
      }
    }
  }
  &.loading-hero-content {
    min-height: 100vh;
  }
  .sf-skeleton {
    position: absolute;
    height: 100vh;
    width: 100vw;
  }
  .s-arrow {
    justify-content: space-between;
    @include for-mobile {
    }
    .swiper-button-next,
    .swiper-button-prev {
      border: 0;
      background: none;
      padding: 0;
      cursor: pointer;
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translatey(-50%);
      @include for-mobile {
        display: block;
      }
      &.swiper-button-disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      @include for-mobile {
        svg {
          height: 35px;
          width: auto;
        }
      }
    }
    .button-prev {
      left: 50px;
      @include for-mobile {
        left: 10px;
      }
    }
    .button-next {
      right: 50px;
      @include for-mobile {
        right: 10px;
      }
    }
  }
  .swiper-slide {
    position: relative;
    padding-bottom: 56.25%;
    @media (max-width: 1023px) {
      padding-bottom: 177.77%;
    }
    .hero-slider__slide {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }
}
</style>
