<template>
  <div class="swiper-slide-video">
    <div class="hero-slider__wrapper">
      <nuxt-img :src="correctImage" preload v-show="false" />
      <style v-if="correctImage">
        :root {
          --image-hp-video-slider: {{`url('${correctImage}')`}};
        }
      </style>
      <client-only>
        <div class="hero-slider__wrapper__video" v-if="getVimeoPlayerOptions()">
          <vueVimeoPlayer
            v-bind="getVimeoPlayerOptions()"
            class="hero-slider__wrapper__video__player"
          />
        </div>
      </client-only>
      <HeroSliderContent
        :background-text="
          sliderData && sliderData.background_text
            ? sliderData.background_text
            : ''
        "
      />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  provide,
  useContext,
} from '@nuxtjs/composition-api';
import NextButton from './NextButton.vue';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import HeroSliderContent from './HeroSliderContent.vue';

export default defineComponent({
  name: 'HeroVideo',
  components: { NextButton, vueVimeoPlayer, HeroSliderContent },
  props: {
    video: {
      type: Object,
      required: true,
    },
    image: {
      type: Object,
      required: true,
    },
    sliderData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    provide('ctasData', props.sliderData.ctas);
    provide('overlayText', props.sliderData.text);
    provide('textColor', props.sliderData.text_color);
    provide('position', props.sliderData.text_position);
    const {
      app: {
        $device: { isDesktop },
      },
      req,
    } = useContext();
    const correctImage = computed(() => {
      return isDesktop
        ? props.image?.desktop || '/splash_screens/icon.png'
        : props.image?.mobile || '/splash_screens/icon.png';
    });

    const getVimeoPlayerOptions = () => {
      const userAgent = req ? req.headers['user-agent'] : navigator.userAgent;
      if (userAgent.includes('Lighthouse')) return null;

      const vimeoPlayerOptions = {
        autoplay: true,
        controls: false,
        loop: true,
        options: {
          muted: true,
          autopause: false,
        },
      };
      const correctVideo = isDesktop
        ? props.video?.video_url_desktop || null
        : props.video?.video_url_mobile || null;
      if (!correctVideo) return null;
      vimeoPlayerOptions[
        correctVideo.includes('http') ? 'video-url' : 'video-id'
      ] = correctVideo;
      return vimeoPlayerOptions;
    };
    return {
      getVimeoPlayerOptions,
      correctImage,
    };
  },
});
</script>

<style lang="scss" scoped>
.hero-slider__wrapper {
  width: 100vw;
  position: relative;
  background-image: var(--image-hp-video-slider);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include for-desktop {
    width: 100%;
  }
  height: 100%;
  @include desktop-boxed;
  overflow: hidden;
  &__video {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    pointer-events: none;
    &__player {
      padding: 56.25% 0 0 0; // 56.25% -> 16:9
      position: relative;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      @media (max-width: 767px) {
        padding: 177.77% 0 0 0; // 177.77% -> 9:16
      }
    }
  }
}
.swiper-slide-video {
  position: relative;
  @media (max-width: 768px) {
    padding-bottom: 0;
    aspect-ratio: 9/16;
  }
  .hero-slider__wrapper {
    @media (min-width: 1023px) {
      aspect-ratio: 1920/1080;
    }
    left: 0;
    top: 0;
    width: 100%;
  }
}
</style>

<style lang="scss">
.hero-slider__wrapper__video__player {
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
