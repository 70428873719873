<template>
  <div>
    <CustomButton
      :layout="textColor === 'white' ? 'blurred' : 'white'"
      v-for="(cta, index) in ctasData.data"
      v-if="cta && cta.active"
      :link="fixUrlForCurrentLocale(`/${cta.link.url}`)"
      class="hero-cta"
      :key="index"
    >
      {{ cta.link.label }}
    </CustomButton>
  </div>
</template>
<script>
import { defineComponent, inject } from '@nuxtjs/composition-api';
import CustomButton from '~/components/General/FormElements/CustomButton.vue';
import { useCurrentLocale } from '~/composables/useCurrentLocale';

export default defineComponent({
  name: 'HeroSliderCtas',
  components: { CustomButton },
  setup() {
    const textColor = inject('textColor');
    const ctasData = inject('ctasData');
    const { fixUrlForCurrentLocale } = useCurrentLocale();

    return { ctasData, textColor, fixUrlForCurrentLocale };
  },
});
</script>
<style lang="scss">
.hero-cta {
  --button-width: 7.5rem;
}
</style>
