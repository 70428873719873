<template>
  <div class="hero-slider__slide">
    <NextButton
      v-if="sliderLength > 1"
      @changeSlide="(value) => $emit('changeSlide', value)"
      position="both"
    />
    <nuxt-img
      @load="$emit('slideLoaded')"
      class="hero-slider__slide__image"
      :src="slideImgSrc || '/splash_screens/icon.png'"
      format="webp"
      fit="contain"
      :preload="slideIndex === 0"
      :loading="slideIndex === 0 ? 'eager' : 'lazy'"
      :alt="slideImgAlt"
      loading="lazy"
    />
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useWindow } from '~/composables';
import NextButton from './NextButton.vue';

export default defineComponent({
  name: 'HeroSlide',
  components: { NextButton },
  props: {
    slideData: {
      type: Object,
      required: true,
    },
    slideIndex: {
      type: Number,
      required: true,
    },
    sliderLength: {
      type: Number,
    },
  },
  setup(props) {
    const { isDesktopOrTablet } = useWindow();
    const slideImgSrc = computed(() =>
      isDesktopOrTablet.value
        ? props.slideData?.image?.desktop
        : props.slideData?.image?.mobile
    );
    const slideImgAlt = computed(() => props.slideData?.image?.alt) || '';

    return {
      slideImgSrc,
      slideImgAlt,
    };
  },
});
</script>

<style lang="scss">
.hero-slider__slide {
  width: 100vw;
  position: relative;
  @include for-desktop {
    width: 100%;
  }
  height: 100%;
  @include desktop-boxed;
  // max-height: 100vh;
  overflow: hidden;
  &__image {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  &__video {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
